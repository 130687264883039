import { format } from 'date-fns';
import { he as heLocale } from 'date-fns/locale';
import { collect } from 'collect.js';

export const formatDate: any = (date: Date, withTime = false) => {
  const today = new Date();
  if (today.toDateString() === date.toDateString()) {
    if (withTime) {
      return 'היום ב-' + format(date, 'HH:mm', { locale: heLocale });
    }
    return 'היום';
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.toDateString() === date.toDateString()) {
    if (withTime) {
      return 'אתמול ב-' + format(date, 'HH:mm', { locale: heLocale });
    }
    return 'אתמול';
  }
  return format(date, 'dd/MM/yyyy', { locale: heLocale });
};

export const sortedByDate = (elements: any, key = 'created_at') => {
  return collect(elements).sortBy(key).reverse().all();
};

export const defaultAvatar = name =>
  `https://ui-avatars.com/api/?background=0fa9ff&color=fff&rounded=false&format=png&length=2&name="${name}"`;

export const defaultAvatarImage =
  'https://res.cloudinary.com/dcodkxpej/image/upload/v1725192676/default-avatar-image_adpnhe.png';

export const parseToMinutes = (time: any): string => {
  const minutes = Math.floor(time / 60);

  const seconds = time.toFixed(0) % 60;

  return `${padTo2Digits(minutes, true)}:${padTo2Digits(seconds)}`;
};

function padTo2Digits(num: number, isMinute = false) {
  return num.toString().padStart(isMinute ? 1 : 2, '0');
}

export const videoStreamUrl = (
  link: string,
  { match = '/upload/', quality = 'sp_auto', format = 'm3u8' } = {},
): string => {
  return link
    .trim()
    .replace(match, `${match}${quality}/`)
    .replace(/\.(mp4|mov)$/, `.${format}`);
};
